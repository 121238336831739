@import "./normalize.scss";
@import "./reset.scss";

:root {
	--top-bar-height: 50px;
	--side-margins: 24px;
	--standart-padding: 8px;
	--big-padding: 16px;
	--app-width: 1920px;
	--left-side-shadow: -10px 0px 16px -6px rgba(0, 0, 26, 0.2);
	--z-index-high: 9;
	--z-index-mid: 8;
	--z-index-low: 7;
	--error: #ff1744;
	--success: #00e676;
	--ms-blue: #0078d4;
	--ms-dark-blue: #106ebe;
}

body {
	--themePrimary: #2196f3;
	--themeLighterAlt: #f6fbfe;
	--themeLighter: #daedfd;
	--themeLight: #badefb;
	--themeTertiary: #78bef7;
	--themeSecondary: #3ba1f4;
	--themeDarkAlt: #1f86da;
	--themeDark: #1a71b8;
	--themeDarker: #135388;
	--neutralLighterAlt: #faf9f8;
	--neutralLighter: #f3f2f1;
	--neutralLight: #edebe9;
	--neutralQuaternaryAlt: #e1dfdd;
	--neutralQuaternary: #d0d0d0;
	--neutralTertiaryAlt: #c8c6c4;
	--neutralTertiary: #a19f9d;
	--neutralSecondary: #605e5c;
	--neutralPrimaryAlt: #3b3a39;
	--neutralPrimary: #323130;
	--neutralDark: #201f1e;
	--black: #000000;
	--white: #ffffff;

	--app-bar: #2196f3;
}

body[data-theme="dark"] {
	--themePrimary: #00bbff;
	--themeLighterAlt: #00070a;
	--themeLighter: #001e29;
	--themeLight: #00384d;
	--themeTertiary: #007099;
	--themeSecondary: #00a5e0;
	--themeDarkAlt: #19c2ff;
	--themeDark: #3dcbff;
	--themeDarker: #70d9ff;
	--neutralLighterAlt: #363e48;
	--neutralLighter: #3c454f;
	--neutralLight: #47505b;
	--neutralQuaternaryAlt: #4e5763;
	--neutralQuaternary: #545d69;
	--neutralTertiaryAlt: #6c7682;
	--neutralTertiary: #eee8e8;
	--neutralSecondary: #f1ebeb;
	--neutralPrimaryAlt: #f4efef;
	--neutralPrimary: #e6dcdc;
	--neutralDark: #f9f7f7;
	--black: #fcfbfb;
	--white: #2f3740;

	--app-bar: #415469;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.txt {
	&_light {
		color: var(--white);
	}
	&_large {
		font-size: 18px;
	}
	&_err {
		color: var(--error);
	}
}

#root {
	height: 100%;
	position: relative;
	background-color: var(--white);
}

.icon-btn {
	height: 16px;

	&:hover {
		cursor: pointer;
	}
}

#app-info {
	display: grid;
	height: 100%;
	width: 100%;
	align-content: center;
	align-items: center;
	justify-content: center;
}
