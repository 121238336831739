.queue {
	height: 100%;
	width: 412px;

	@media screen and (max-width: 1980px) {
		right: 0;
	}

	@media screen and (max-width: 600px) {
		width: 100%;
		min-width: 270px;
		padding-bottom: 8px;
	}

	&__error-description {
		display: grid;
		grid-auto-flow: column;
		grid-auto-columns: auto -webkit-max-content;
		grid-auto-columns: auto max-content;
		-moz-column-gap: var(--side-margins);
		     column-gap: var(--side-margins);

		&-msg {
			display: grid;
			grid-auto-flow: column;
			grid-auto-columns: auto -webkit-max-content;
			grid-auto-columns: auto max-content;
			-moz-column-gap: 5px;
			     column-gap: 5px;
		}
	}

	&__list {
		display: grid;
		grid-auto-rows: -webkit-max-content;
		grid-auto-rows: max-content;
		row-gap: 16px;
		height: 100%;
		overflow: auto;
	}

	&_closed {
		width: 0;
		opacity: 0;
		visibility: hidden;
	}

	&__card {
		display: grid;
		align-items: center;
		width: 100%;
		min-height: 70px;
		padding: 8px;
		transition: background-color ease 3ms;

		&:hover {
			background-color: var(--neutralLighterAlt);
		}

		&-header {
			display: grid;
			grid-template-columns: auto -webkit-max-content;
			grid-template-columns: auto max-content;
			justify-content: space-between;
			-moz-column-gap: var(--side-margins);
			     column-gap: var(--side-margins);
			align-items: center;
			height: 24px;
		}

		&-title {
			display: grid;

			&-text {
				text-overflow: ellipsis;
				overflow: hidden;
				line-height: 1.4;
			}
		}

		&-other {
			display: grid;
			grid-auto-flow: column;
			justify-content: end;
			-moz-column-gap: var(--side-margins);
			     column-gap: var(--side-margins);
		}

		&-cancel-button {
			height: 14px;
		}
	}

	&__text-highlight {
		color: var(--themePrimary);
	}

	&__info {
		width: 100%;
		display: grid;

		&-wrap {
			display: grid;
			grid-auto-flow: dense;
			grid-auto-rows: -webkit-max-content;
			grid-auto-rows: max-content;
			row-gap: 10px;
			width: 300px;
			padding: 16px;
		}

		&-preview {
			display: grid;
			row-gap: 10px;
		}

		&-thmbnl {
			width: 100%;
			max-height: 154px;
		}

		&-label {
			color: var(--neutralTertiary);
		}
	}
}
