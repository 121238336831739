.actions {
	max-width: var(--app-width);
	width: 100%;
	margin: auto;
	z-index: 9;

	&-wrap {
		border-bottom: 1px solid var(--gray);
		width: 100%;
		display: grid;
		align-items: center;
		background-color: var(--white);
	}

	&__button {
		align-self: center;
		width: 100%;
	}

	&__select {
		&-button {
			width: 100%;
		}
		&-label {
			cursor: inherit;
		}
		&-input {
			display: none;
		}
	}
}
