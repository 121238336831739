.header {
	display: grid;
	justify-items: center;
	align-items: center;
	height: var(--top-bar-height);
	width: 100%;
	background-color: var(--app-bar);
	z-index: 9;

	&__about {
		display: grid;
		grid-auto-flow: column;
		grid-auto-columns: -webkit-max-content;
		grid-auto-columns: max-content;
		align-items: center;
		-moz-column-gap: var(--standart-padding);
		     column-gap: var(--standart-padding);
	}

	&__content-wrap {
		max-width: var(--app-width);
		display: grid;
		grid-auto-flow: column;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 0 var(--side-margins);
	}

	&__persona {
		display: grid;
		justify-content: end;
	}

	&__actions {
		display: grid;
		grid-auto-flow: column;
		justify-content: space-between;
		grid-column-gap: var(--standart-padding);
	}

	&__bell {
		border-radius: 50%;
		color: #ffffff;
		font-size: 14px;
		font-weight: 600;
		line-height: 32px;
		height: 32px;
		background-color: var(--ms-blue);

		&:hover {
			cursor: pointer;
			color: #ffffff;
			background-color: var(--ms-dark-blue);
		}
	}
}
