.file-list {
	z-index: 1;
	&__tooltip {
		margin: 4px;
		display: grid;
		grid-auto-flow: dense;
		grid-auto-rows: -webkit-max-content;
		grid-auto-rows: max-content;
		row-gap: 7px;
	}
	&__thmbnl {
		width: 22px;
		height: 22px;

		&_big {
			max-height: 154px;
			max-width: 272px;
		}
	}
	&__thmbnl-meta {
		width: 56px;
		height: 100%;
	}
	&__meta-wrap {
		display: grid;
		align-items: center;
		height: 32px;

		&_clickable {
			cursor: pointer;
		}
	}
	&__meta-data {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	&-wrap {
		display: grid;
		margin-left: var(--side-margins);
		margin-top: 0;
		margin-right: var(--side-margins);
		margin-bottom: var(--side-margins);
	}
}
