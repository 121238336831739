@use "../../styles/mixins";

.upload {
	display: grid;
	position: relative;
	height: 100%;
	grid-template-rows: auto -webkit-max-content;
	grid-template-rows: auto max-content;
	width: 412px;

	@media screen and (max-width: 600px) {
		width: 100%;
		min-width: 270px;
	}
	&-btn {
		height: 32px;
		width: 100%;
		display: grid;
		justify-content: flex-start;
		white-space: nowrap;
		overflow: hidden;
	}
	&-txt {
		margin: 0 4px;
		overflow: hidden;
		text-overflow: ellipsis;

		&_overflow {
			white-space: nowrap;
		}
	}
	&-folders {
		display: grid;
		grid-template-rows: 88px auto;
		overflow: auto;
		row-gap: 6px;
		position: relative;

		&__header {
			overflow: hidden;
			display: grid;
			grid-template-rows: 49px 24px;
			grid-template-rows: 32px 48px;
			grid-row-gap: var(--standart-padding);

			&-actions {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-template-rows: 1fr;
				border-bottom: 1px solid var(--gray);
				border-top: 1px solid var(--gray);
				align-items: center;
			}
		}
		&__content {
			display: grid;
			grid-auto-flow: dense;
			grid-auto-rows: -webkit-max-content;
			grid-auto-rows: max-content;
			height: 100%;
			overflow-x: hidden;
			overflow-y: auto;
		}

		&__footer {
			display: grid;
		}
	}
	&-locations {
		display: grid;
		grid-auto-rows: -webkit-max-content;
		grid-auto-rows: max-content;

		&__recent {
			display: grid;
			grid-template-rows: 24px 20px 32px;
		}
	}
	&-actions {
		display: grid;
		grid-auto-flow: dense;
		row-gap: 17px;
		align-content: center;
		align-items: center;

		@media screen and (max-width: 600px) {
			grid-auto-flow: dense;
			grid-auto-rows: -webkit-max-content;
			grid-auto-rows: max-content;
			grid-auto-columns: 100%;
			row-gap: var(--standart-padding);
			padding-bottom: var(--standart-padding);
		}

		&__upload-type {
			margin: 0;
			font-weight: normal;
			display: grid;
			grid-auto-flow: column;
			align-content: center;
			align-items: center;
			justify-content: start;
			-moz-column-gap: 40px;
			     column-gap: 40px;
			height: 20px;
		}

		&__upload-type-info-icon {
			padding: 0;
		}

		&__btns {
			display: grid;
			grid-auto-flow: column;
			grid-auto-columns: -webkit-max-content;
			grid-auto-columns: max-content;
			-moz-column-gap: var(--standart-padding);
			     column-gap: var(--standart-padding);

			@media screen and (max-width: 600px) {
				display: grid;
				justify-content: center;
				align-content: center;
				-moz-column-gap: var(--standart-padding);
				     column-gap: var(--standart-padding);
				grid-auto-flow: column;
				align-self: center;
				height: 56px;
				width: 100%;
				grid-auto-columns: 3fr 1fr;
			}

			&-btn {
				@include mixins.centeredContentBtn();
			}
		}
	}
	&-create-folder-callout {
		width: 320px;
		height: 184px;
		padding: 20px 24px;

		&__body {
			display: grid;
			row-gap: 20px;
			height: 144px;
			align-content: space-between;
		}
		&__btns {
			display: grid;
			grid-auto-flow: column;
			justify-content: end;
			-moz-column-gap: 10px;
			     column-gap: 10px;
		}
	}
	&__path {
		display: grid;
		grid-auto-flow: column;
		grid-column-gap: var(--standart-padding);

		&-icon {
			font-size: 12px;
		}
	}
	&__breadcrumb {
		margin: 0;
		height: 32px;
		display: grid;
		align-content: center;
	}
	&__locations {
		display: grid;
		grid-auto-flow: dense;
		grid-auto-rows: 32px;
		align-items: center;
		justify-items: left;
	}
	&__btn {
		white-space: nowrap;
	}
}
