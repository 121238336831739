@mixin mobileBar {
	display: grid;
	justify-content: center;
	align-content: center;
	column-gap: 8px;
	grid-auto-flow: column;
	align-self: center;
	border-top: 1px solid var(--l-gray);
	height: 56px;
	width: 100%;
	padding-left: 24px;
	padding-right: 24px;
}

@mixin mobileBarStandalone {
	@include mobileBar();

	background-color: var(--white);
	padding-top: 8px;
	padding-bottom: 8px;
	position: fixed;
	width: 100%;
	bottom: 0;
	z-index: var(--z-index-mid);
}

@mixin centeredContentBtn {
	display: grid;
	justify-content: center;
}
