@use "styles/mixins";

@mixin fullScreenGrid {
	display: grid;
	height: 100%;
	width: 100%;
}

@mixin app {
	@include fullScreenGrid();

	position: relative;
	padding-bottom: var(--standart-padding);

	@media screen and (max-width: 600px) {
		padding-bottom: calc(var(--standart-padding) + 56px);
	}
}

.main {
	@include fullScreenGrid();

	max-width: var(--app-width);
	margin-left: auto;
	margin-right: auto;
	position: relative;
	overflow: auto;
}

.app-classic {
	@include app();

	grid-template-rows: 50px 45px calc(100% - 95px);
}

.app-embedded {
	@include app();

	grid-template-rows: 45px calc(100% - 45px);
}

.app-error {
	@include fullScreenGrid();

	align-content: center;
	justify-content: center;
	grid-auto-flow: column;
	align-items: center;
}

.app-info {
	display: grid;
	justify-content: center;
	align-content: center;
	justify-items: center;
	row-gap: 24px;

	&__text-highlight {
		color: var(--themePrimary);
	}

	&__home-img {
		width: 100%;
	}
}

.app-mobile-bar {
	display: none;

	@media screen and (max-width: 600px) {
		@include mixins.mobileBarStandalone();

		grid-auto-columns: 3fr 1fr;
	}

	&__btn {
		@include mixins.centeredContentBtn();
	}
}

.ignored-files {
	display: grid;
	grid-auto-flow: row;
	row-gap: 12px;

	&-container {
		min-height: 200px;
		max-height: 600px;
		height: 100%;
		max-width: 600px;
		min-width: 200px;
		width: 100%;
		padding: 24px;
		row-gap: 15px;
		display: grid;
		grid-template-rows: -webkit-max-content auto;
		grid-template-rows: max-content auto;
		align-items: start;
	}
}
