.user {
	cursor: pointer;

	&_unauthorized {
		display: grid;
		grid-auto-flow: column;
		-moz-column-gap: 10px;
		     column-gap: 10px;
		align-items: center;
		color: var(--white);
	}

	&__login {
		color: var(--white);
	}

	&__callout {
		display: grid;
		grid-auto-flow: dense;
		min-width: 120px;
	}

	&__callout-items {
		display: flex;
		height: 36px;
	}
}
