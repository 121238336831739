.panel {
	position: absolute;
	display: grid;
	justify-content: center;
	grid-auto-rows: -webkit-max-content;
	grid-auto-rows: max-content;
	grid-template-columns: 100%;
	padding-left: var(--side-margins);
	padding-top: 16px;
	padding-bottom: 16px;
	padding-right: var(--side-margins);
	background-color: var(--white);
	z-index: var(--z-index-high);
	transition: all 0.15s cubic-bezier(0.65, 0.05, 0.36, 1),
		background-color 0s ease;
	grid-template-rows: 42px calc(100% - 42px);
	overflow: hidden;
	box-shadow: var(--left-side-shadow);
	align-content: space-between;
	right: 0;
	row-gap: 18px;

	@media screen and (max-width: 600px) {
		padding-bottom: 0;
	}

	&_classic {
		top: 50px;
		height: calc(100% - 50px);
	}

	&_embedded {
		top: 45px;
		height: calc(100% - 45px);
	}

	&_open {
		width: 460px;
		opacity: 1;

		@media screen and (max-width: 600px) {
			width: 100vw;
		}

		@media screen and (min-width: 1920px) {
			width: calc(((100vw - var(--app-width)) / 2) + 460px);
		}
	}

	&_closed {
		width: 0;
		opacity: 0;
		visibility: hidden;
	}

	&__header {
		display: grid;
		grid-auto-flow: column;
		align-items: center;
		overflow: hidden;
		-moz-column-gap: 46px;
		     column-gap: 46px;
		grid-auto-columns: auto -webkit-max-content -webkit-max-content;
		grid-auto-columns: auto max-content max-content;
		width: calc(460px - (var(--side-margins) * 2));

		@media screen and (max-width: 600px) {
			width: 100%;
		}
	}

	&__title {
		height: 42px;
		display: grid;
		align-items: center;
		white-space: nowrap;
		text-overflow: ellipsis;
		justify-content: left;
	}

	&__body {
		height: calc(100% - 18px);
		width: calc(460px - (var(--side-margins) * 2));

		@media screen and (max-width: 600px) {
			width: 100%;
		}
	}
}
