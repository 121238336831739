.dropzone {
	&-wrap {
		position: fixed;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
		background-color: var(--white);
		z-index: 10;
		display: grid;
		align-items: center;
		justify-items: center;

		&_hidden {
			visibility: hidden;
		}
	}

	border-width: 4px;
	border-radius: 2px;
	border-color: var(--neutralPrimary);
	border-style: dashed;
	background-color: var(--white);
	color: var(--neutralPrimary);
	outline: none;
	transition: border 0.24s ease-in-out;
	height: calc(100% - 30px);
	width: calc(100% - 20px);
	margin: 10px;
	display: grid;
	justify-content: center;
	align-content: center;

	&_active {
		border-color: var(--themePrimary);
	}
	&_accept {
		border-color: var(--success);
	}
	&_reject {
		border-color: var(--error);
	}
}
