@use "../../styles/mixins";

.add-metadata {
	display: grid;
	grid-row-gap: 20px;
	width: 410px;

	@media screen and (max-width: 600px) {
		width: 100%;
		min-width: 270px;
		height: 100%;
		align-content: space-between;
	}

	&__content {
		&-datalist {
			display: grid;
		}
	}

	&__controls {
		display: grid;
		-moz-column-gap: var(--standart-padding);
		     column-gap: var(--standart-padding);
		grid-auto-flow: column;
		grid-auto-columns: -webkit-max-content;
		grid-auto-columns: max-content;

		@media screen and (max-width: 600px) {
			@include mixins.mobileBar();

			grid-auto-columns: 3fr 1fr;
		}

		&-btn {
			@include mixins.centeredContentBtn();
		}
	}
}
